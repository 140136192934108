import React, { PropsWithChildren, useMemo } from "react";
import {
  css,
  Flex,
  FlexAlignment,
  flexAlignments,
  FlexCss,
  FlexDirection,
  FlexDirectionCss,
  Padding,
  PaddingCss,
  PaddingX,
  PaddingXCss,
  PaddingY,
  PaddingYCss,
} from "../helper";
import { PropsWithStyle } from "../type.common";

export const Box = (
  props: PropsWithChildren<
    PropsWithStyle<{
      flex?: Flex;
      flex_direction?: FlexDirection;
      padding?: Padding;
      padding_x?: PaddingX;
      padding_y?: PaddingY;
      alignment?: FlexAlignment;
    }>
  >
) => {
  const {
    padding,
    style_names,
    padding_x,
    padding_y,
    alignment,
    flex,
    flex_direction,
  } = props;
  const styles = useMemo(() => {
    const clazzes = [];
    if (flex) {
      clazzes.push(FlexCss[flex]);
    }
    if (flex_direction) {
      clazzes.push(FlexDirectionCss[flex_direction]);
    }
    if (alignment) {
      clazzes.push(flexAlignments[alignment]);
    }
    clazzes.push(
      padding ? PaddingCss[padding] : null,
      padding_x ? PaddingXCss[padding_x] : null,
      padding_y ? PaddingYCss[padding_y] : null,
      css(style_names)
    );
    return css(clazzes);
  }, [
    style_names,
    padding,
    padding_x,
    padding_y,
    alignment,
    flex,
    flex_direction,
  ]);

  return <div className={styles}>{props.children}</div>;
};
