import React, { PropsWithChildren, useMemo } from "react";

const sizes = {
  small: "uc_w-6 uc_h-6",
  medium: "uc_w-8 uc_h-8",
  normal: "uc_w-10 uc_h-10",
  large: "uc_w-20 uc_h-20",
  xlarge: "uc_w-36 uc_h-36",
};

const AvatarDefault = React.memo(() => {
  return (
    <svg
      className={`uc_absolute uc_w-8 uc_h-8 uc_text-gray-400`}
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
});

export type AvatarSize = keyof typeof sizes;

export const AvatarCircle = (
  props: PropsWithChildren<{
    size?: AvatarSize;
    img_src?: string;
    img_alt?: string;
  }>
) => {
  const { size = "normal", img_src, img_alt = "", children } = props;

  const imgCss = useMemo(() => {
    return `${sizes[size]} uc_rounded-full`;
  }, [size]);

  const divCss = useMemo(() => {
    return `uc_relative uc_flex uc_items-center uc_justify-center ${sizes[size]} uc_overflow-hidden uc_bg-gray-100 uc_rounded-full dark:uc_bg-gray-600`;
  }, [size]);

  return (
    <div className={divCss}>
      {img_src ? (
        <img className={imgCss} src={img_src} alt={img_alt}></img>
      ) : children ? (
        children
      ) : (
        <AvatarDefault />
      )}
    </div>
  );
};
