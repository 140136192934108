import React from "react";
import { TextColorCss } from "../helper";
import { XInfoProps } from "./information.type";

export const XInfo = <T,>(props: XInfoProps<T>) => {
  const { value, specs, minHeaderWidth = 170 } = props;
  return (
    <dl>
      {specs.map((spec, index) => {
        return (
          <div
            key={index}
            className={`uc_py-2.5 uc_flex uc_flex-col md:uc_flex-row hover:uc_bg-gray-100 uc_border-b uc_border-gray-100 last:uc_border-b-0`}
          >
            <dt
              className={`uc_flex-none uc_px-4 sm:uc_justify-end uc_font-medium ${TextColorCss.PrimaryLight}`}
              style={{ minWidth: `${minHeaderWidth}px` }}
            >
              {spec.renderLabel
                ? spec.renderLabel(value)
                : spec.label
                ? spec.label
                : null}
            </dt>
            <dd className={`uc_px-4 uc_flex-1`}>
              {spec.renderItemValue
                ? spec.renderItemValue(value)
                : spec.getItemValueAsString
                ? spec.getItemValueAsString(value)
                : null}
            </dd>
          </div>
        );
      })}
    </dl>
  );
};
