import React, { PropsWithChildren, useMemo } from "react";
import {
  BgColor,
  BgColorCss,
  BorderColorCss,
  css,
  PaddingCss,
  PaddingXCss,
  PaddingYCss,
} from "../../helper";
import { PropsWithStyle } from "../../type.common";
import { useCardContext } from "../card.hook";

export const CardFooter = (
  props: PropsWithChildren<PropsWithStyle<{ bgcolor?: BgColor }>>
) => {
  const { children, style_names, bgcolor } = props;
  const context = useCardContext();

  const styles = useMemo(() => {
    const names = ["uc_self-end uc_w-full"];
    if (context.padding_x) {
      names.push(PaddingXCss[context.padding_x]);
    }
    if (context.padding_y) {
      names.push(PaddingYCss[context.padding_y]);
    }
    if (context.padding) {
      names.push(PaddingCss[context.padding]);
    }
    if (style_names) {
      names.push(css(style_names));
    }

    const bg = BgColorCss[bgcolor ? bgcolor : context.bgcolor];
    if (bg) {
      names.push(bg);
    }

    if (context.show_border) {
      names.push("uc_rounded-b-md", "uc_border-t", BorderColorCss.Default);
    } else {
      names.push("uc_rounded-b-md", "uc_border-t", BorderColorCss.Transparent);
    }

    return names.join(" ");
  }, [style_names, bgcolor]);

  return <div className={styles}>{children}</div>;
};
