import React, { useMemo } from "react";
import { css } from "../helper";
import { BtnProps } from "../type.common";

export const BtnLink = (props: BtnProps) => {
  const { style_names = [], doOnClick } = props;
  const styleCss = useMemo(() => css(style_names), [style_names]);
  return (
    <button
      ref={props.ref}
      type="button"
      className={`${styleCss} focus:uc_outline-none uc_border-b uc_border-transparent hover:uc_border-primaryDark hover:uc_text-primaryDark uc_flex uc_flex-row uc_items-center`}
      onClick={(event) => {
        event.preventDefault();
        if (doOnClick) {
          doOnClick();
        }
      }}
    >
      {props.children}
    </button>
  );
};
