import React, { Children, PropsWithChildren, useMemo } from "react";
import {
  css,
  Flex,
  FlexAlignment,
  flexAlignments,
  FlexCss,
  Padding,
  PaddingCss,
  PaddingX,
  PaddingXCss,
  PaddingY,
  PaddingYCss,
} from "../helper";
import { PropsWithStyle } from "../type.common";

export const Row = (
  props: PropsWithChildren<
    PropsWithStyle<{
      flex?: Flex;
      padding?: Padding;
      padding_x?: PaddingX;
      padding_y?: PaddingY;
      alignment?: FlexAlignment;
    }>
  >
) => {
  const {
    flex = "Initial",
    padding,
    style_names,
    padding_x,
    padding_y,
    alignment = "start",
  } = props;
  const styles = useMemo(() => {
    return css([
      "uc_flex",
      "uc_flex-col",
      "uc_space-y-2.5",
      "md:uc_flex-row",
      "md:uc_space-x-2.5",
      "md:uc_space-y-0",
      flexAlignments[alignment],
      padding ? PaddingCss[padding] : null,
      padding_x ? PaddingXCss[padding_x] : null,
      padding_y ? PaddingYCss[padding_y] : null,
      css(style_names),
    ]);
  }, [style_names, padding, padding_x, padding_y, alignment]);

  return (
    <div className={styles}>
      {Children.map(props.children, (child, index) => {
        if (child) {
          return (
            <div key={`r_${index}`} className={`${FlexCss[flex]}`}>
              {child}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};
