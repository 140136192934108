import React, { useMemo } from "react";
import { Central } from "../central";
import { css, SizingCss } from "../helper";
import { Spin } from "../loading";
import { RowFixed } from "../row";
import { BtnProps } from "../type.common";

export const Btn = (
  props: BtnProps & { type?: "button" | "submit" | "reset" | undefined }
) => {
  const {
    style_names,
    doOnClick,
    show_loading = false,
    type = "button",
    full_width = true,
  } = props;
  const styleCss = useMemo(() => {
    const values = [];
    if (style_names) {
      values.push(css(style_names));
    }
    if (full_width) {
      values.push(`${SizingCss.FullWidth}`);
    }
    return values.join(" ");
  }, [style_names]);

  return (
    <button
      ref={props.ref}
      type={type}
      className={styleCss}
      onClick={(event) => {
        event.preventDefault();
        doOnClick();
      }}
      disabled={show_loading}
    >
      <>
        {show_loading ? (
          <Central>
            <RowFixed>
              {props.children} <Spin />
            </RowFixed>
          </Central>
        ) : (
          props.children
        )}
      </>
    </button>
  );
};
