import React, { PropsWithChildren, useEffect, useState } from "react";
import { Loading } from ".";
import { Central } from "../central";
import { SizingCss } from "../helper";

export const LoadingScreen = (
  props: PropsWithChildren<{ showAfterMils?: number }>
) => {
  const { showAfterMils = 300, children } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), showAfterMils);
    return () => {
      clearTimeout(timer);
    };
  }, [showAfterMils]);

  return (
    <>
      {show ? (
        <Central style_names={[SizingCss.FullVHeight]}>
          <Loading loadingColor={"#3730a3"} />
          {children}
        </Central>
      ) : null}
    </>
  );
};
