import React, { useMemo } from "react";
import { ChildrenFunc } from "../misc";

export const StackedList = <T,>(props: {
  items: T[];
  children: ChildrenFunc<T>;
  show_separator?: boolean;
}) => {
  const { children, items, show_separator = false } = props;

  const css = useMemo(() => {
    const styles = ["uc_list-inside"];
    if (show_separator) {
      styles.push("uc_divide-y uc_divide-gray-100");
    }
    return styles.join(" ");
  }, [show_separator]);

  return (
    <ul role="list" className={css}>
      {children
        ? items.map((item, index) => (
            <li key={index} className="uc_py-4">
              {children(item)}
            </li>
          ))
        : null}
    </ul>
  );
};
