import React, { PropsWithChildren, useMemo } from "react";
import {
  Padding,
  PaddingCss,
  PaddingX,
  PaddingXCss,
  PaddingY,
  PaddingYCss,
  css,
} from "../helper";
import { PropsWithStyle } from "../type.common";

export const Container = (
  props: PropsWithChildren<
    PropsWithStyle<{
      padding?: Padding;
      padding_x?: PaddingX;
      padding_y?: PaddingY;
      center?: boolean;
    }>
  >
) => {
  const { children, style_names, padding, center, padding_x, padding_y } =
    props;
  const styleClasses = useMemo(() => {
    let clazzes = ["uc_container"];
    if (styleClasses) {
      clazzes = clazzes.concat(styleClasses);
    }
    if (center) {
      clazzes.push("uc_mx-auto");
    }

    let padding_styles = [];
    if (padding) {
      padding_styles.push(PaddingCss[padding]);
    }
    if (padding_x) {
      padding_styles.push(PaddingXCss[padding]);
    }
    if (padding_y) {
      padding_styles.push(PaddingYCss[padding]);
    }
    if (padding_styles.length == 0) {
      padding_styles.push("uc_py-4", "uc_px-4");
    }
    clazzes.push(padding_styles.join(" "));
    return css(clazzes);
  }, [style_names, center, padding, padding_x, padding_y]);

  return <div className={styleClasses}>{children}</div>;
};
