import React, { PropsWithChildren, useMemo } from "react";
import { KeyType } from "../helper";
import { PropsWithStyle } from "../type.common";

const span_mapping: { [key: number]: string } = {
  1: "md:uc_col-span-1",
  2: "md:uc_col-span-2",
  3: "md:uc_col-span-3",
  4: "md:uc_col-span-4",
  5: "md:uc_col-span-5",
  6: "md:uc_col-span-6",
  7: "md:uc_col-span-7",
  8: "md:uc_col-span-8",
  9: "md:uc_col-span-9",
  10: "md:uc_col-span-10",
  11: "md:uc_col-span-11",
  12: "md:uc_col-span-12",
};

export type Span = keyof KeyType<typeof span_mapping>;

export const ColumnSpan = (
  props: PropsWithChildren<PropsWithStyle<{ span: Span }>>
) => {
  const { span, style_names } = props;

  const css = useMemo(() => {
    const items = [];
    if (span_mapping[span]) {
      items.push(span_mapping[span]);
    }
    if (style_names) {
      items.push(style_names.join(" "));
    }
    return items.join(" ");
  }, [span, style_names]);

  return <div className={css}>{props.children}</div>;
};
