import React, { PropsWithChildren } from "react";
import { FlexAlignment, flexAlignments } from "../helper";
import { RowFixed } from "../row";

export const BtnGroup = (
  props: PropsWithChildren<{ alignment?: FlexAlignment }>
) => {
  const { alignment = "start" } = props;
  return (
    <RowFixed flex="Initial" style_names={[flexAlignments[alignment]]}>
      {props.children}
    </RowFixed>
  );
};
