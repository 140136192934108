import React, { PropsWithChildren, ReactElement } from "react";

const Separator = React.memo(() => {
  return (
    <svg
      className="uc_w-3 uc_h-3 uc_text-gray-400 uc_mx-1"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 6 10"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m1 9 4-4-4-4"
      />
    </svg>
  );
});

export const PageBreadcrumb = (
  props: PropsWithChildren<{ root: ReactElement }>
) => {
  const { root } = props;
  return (
    <nav className="uc_flex uc_flex-row" aria-label="breadcrumb">
      <ol className="uc_inline-flex uc_items-center uc_space-x-1 md:uc_space-x-1.5">
        <li className="uc_inline-flex uc_items-center">{root}</li>
        {React.Children.map(props.children, (child, index) => {
          if (child) {
            return (
              <li>
                <div className="uc_flex uc_items-center">
                  <Separator />
                  {child}
                </div>
              </li>
            );
          }
          return null;
        })}
      </ol>
    </nav>
  );
};
