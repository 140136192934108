import React, { PropsWithChildren, useMemo } from "react";
import { FontWeight, FontWeightCss, TextSize, TextSizeCss } from "../../helper";

export const InlineText = (
  props: PropsWithChildren<{ text_size?: TextSize; font_weight?: FontWeight }>
) => {
  const { text_size, font_weight } = props;
  const css = useMemo(() => {
    const items = [];
    if (text_size) {
      items.push(TextSizeCss[text_size]);
    }
    if (font_weight) {
      items.push(FontWeightCss[font_weight]);
    }
    return items.join(" ");
  }, [font_weight, text_size]);

  return <span className={css}>{props.children}</span>;
};
