import React, { PropsWithChildren, useMemo } from "react";
import { css, KeyType } from "../helper";
import { PropsWithStyle } from "../type.common";

const grilColsDict = {
  Col1: "md:uc_grid-cols-1",
  Col2: "md:uc_grid-cols-2",
  Col3: "md:uc_grid-cols-3",
  Col4: "md:uc_grid-cols-4",
};

export type GrilCols = keyof KeyType<typeof grilColsDict>;

export const Grid = (
  props: PropsWithChildren<PropsWithStyle<{ col?: GrilCols }>>
) => {
  const { children, style_names = [], col = "Auto" } = props;
  const styles = useMemo(() => {
    const styles = [...style_names];
    styles.push("uc_grid uc_grid-cols-1 uc_gap-4");
    styles.push(grilColsDict[col]);
    return css(styles);
  }, [style_names]);

  return <div className={styles}>{children}</div>;
};
