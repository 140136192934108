import React, { PropsWithChildren, useMemo } from "react";
import {
  css,
  Padding,
  PaddingX,
  PaddingY,
  PaddingCss,
  PaddingXCss,
  PaddingYCss,
  FlexAlignment,
  flexAlignments,
} from "../helper";
import { PropsWithStyle } from "../type.common";

export const RawRow = (
  props: PropsWithChildren<
    PropsWithStyle<{
      padding?: Padding;
      padding_x?: PaddingX;
      padding_y?: PaddingY;
      alignment?: FlexAlignment;
    }>
  >
) => {
  const {
    padding,
    padding_x,
    padding_y,
    style_names,
    alignment = "start",
  } = props;

  const styles = useMemo(() => {
    return css([
      "uc_flex",
      "uc_flex-row",
      "uc_space-x-2.5",
      flexAlignments[alignment],
      padding ? PaddingCss[padding] : null,
      padding_x ? PaddingXCss[padding_x] : null,
      padding_y ? PaddingYCss[padding_y] : null,
      css(style_names),
    ]);
  }, [style_names, padding, padding_x, padding_y, alignment]);
  return <div className={styles}>{props.children}</div>;
};
