import React, { useState, PropsWithChildren } from "react";
import { Locales } from "../helper";
import { I18NBundles } from "../i18n";
import AppContext, { supportedLocales } from "./app_context";

const locales = Object.keys(Locales).map((it) => `${Locales[it]}`);

export const XApp = (
  props: PropsWithChildren<{
    i18NBundles?: I18NBundles;
    appName: string;
    userLocale?: string;
  }>
) => {
  const { children, appName, i18NBundles, userLocale = Locales.En_US } = props;
  const [locale, setLocale] = useState<string>(userLocale);

  return (
    <AppContext.Provider
      value={{
        onChangeLocale: (newLocale) => {
          if (locales.includes(newLocale) && locale !== newLocale) {
            setLocale(newLocale);
          }
        },
        getLocale: () => `${locale}`,
        i18NBundles,
        getSupportedLocales: () => supportedLocales,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
