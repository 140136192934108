import React from "react";
import { Btn } from "../btn_normal";
import { BtnCss } from "../helper";
import { BtnProps } from "../type.common";

export const BtnCancel = (props: BtnProps) => {
  const { style_names, ...rest } = props;

  return (
    <Btn
      style_names={
        style_names ? [...style_names, BtnCss.Cancel] : [BtnCss.Cancel]
      }
      {...rest}
    >
      {props.children}
    </Btn>
  );
};
