import React, { PropsWithChildren, useMemo } from "react";

const levelColorMapping = {
  info: "uc_bg-info",
  danger: "uc_bg-dangerLight",
  warning: "uc_bg-warning",
  success: "uc_bg-success",
  default: "uc_bg-blue-100",
};

export type AlertLevel = keyof typeof levelColorMapping;

export const Alert = (props: PropsWithChildren<{ level?: AlertLevel }>) => {
  const { level = "default" } = props;
  const styleNames = useMemo(() => {
    const names = ["uc_rounded-lg", "uc_py-5", "uc_px-6", "uc_mb-4"];
    names.push(levelColorMapping[level] || levelColorMapping["default"]);
    return names.join(" ");
  }, [level]);

  return <div className={styleNames}>{props.children}</div>;
};
