import React from "react";
import { BgColor, BgColorCss, Padding, PaddingX, PaddingY } from "../helper";

export interface CardContext {
  show_border?: boolean;
  padding?: Padding;
  padding_x?: PaddingX;
  padding_y?: PaddingY;
  bgcolor?: BgColor;
}

export const CardContextInstance = React.createContext<CardContext>({});
