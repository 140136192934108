import React, { PropsWithChildren, useMemo } from "react";
import { Central } from "../central";
import { BtnCss, css } from "../helper";
import { Spin } from "../loading";
import { RowFixed } from "../row";
import { BtnProps } from "../type.common";

export const BtnSubmit = (
  props: PropsWithChildren<Pick<BtnProps, "show_loading" | "style_names">>
) => {
  const { show_loading = false, style_names } = props;
  const styleCss = useMemo(() => css(style_names), [style_names]);

  return (
    <button
      type={"submit"}
      disabled={show_loading}
      className={`${BtnCss.Primary} ${styleCss}`}
    >
      <>
        {show_loading ? (
          <Central>
            <RowFixed>
              {props.children} <Spin />
            </RowFixed>
          </Central>
        ) : (
          props.children
        )}
      </>
    </button>
  );
};
