import React from "react";
import { Locales } from "../helper";
import { I18NBundles } from "../i18n";

export const supportedLocales = {
  [Locales.En_US]: "English",
  [Locales.Vi_VN]: "Vietnamese",
};

const AppContext = React.createContext<{
  appName?: string;
  i18NBundles?: I18NBundles;
  onChangeLocale?: (locale: string) => void;
  text?: (key: string) => string;
  getLocale: () => string;
  getSupportedLocales: () => { [languageCode: string]: string };
}>({
  getLocale: () => Locales.En_US,
  getSupportedLocales: () => supportedLocales,
});

export default AppContext;
