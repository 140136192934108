import React, { useMemo } from "react";
import { css } from "../helper";
import { BtnProps } from "../type.common";

export const BtnCircle = (props: BtnProps<{ label: string }>) => {
  const { children, style_names, doOnClick, label } = props;
  const styleCss = useMemo(() => css(style_names), [style_names]);
  return (
    <button
      aria-label={label}
      type="button"
      className={`uc_flex uc_rounded-full uc_items-center uc_justify-center focus:uc_outline-none ${styleCss}`}
      onClick={() => doOnClick()}
    >
      {children}
    </button>
  );
};
