import React from "react";
import { BtnCircle } from "../btn_circle";
import { PencilIcon } from "../icons";

export const BtnIconEdit = (props: { doOnClick: () => void }) => {
  const { doOnClick } = props;
  return (
    <BtnCircle
      label="edit"
      doOnClick={doOnClick}
      style_names={["uc_btn_circle"]}
    >
      <PencilIcon />
    </BtnCircle>
  );
};
