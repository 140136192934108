import React, { useMemo } from "react";
import { Btn } from "../btn_normal";
import { BtnCss } from "../helper";
import { BtnProps } from "../type.common";

export const BtnDanger = (props: BtnProps) => {
  const { style_names, ...rest } = props;
  return (
    <Btn
      style_names={
        style_names ? [...style_names, BtnCss.Danger] : [BtnCss.Danger]
      }
      {...rest}
    >
      {props.children}
    </Btn>
  );
};
