import { I18NBundles } from "./i18n.type";

const resolve = (
  bundles: I18NBundles,
  locale: string,
  msgKey: string,
  params?: (string | number)[]
): string | null => {
  if (bundles) {
    const bundle = bundles[locale];
    if (bundle) {
      const msg = bundle[msgKey];
      if (msg && params && params.length > 0) {
        const parts = msg.split("{}");
        return parts.reduce((prev, cur, index) => {
          return `${prev}${cur}${params.length > index ? params[index] : ""}`;
        }, "");
      }
      return msg || null;
    }
  }
  return null;
};

export const text = (
  bundles: I18NBundles,
  locale: string,
  msgKey: string,
  params?: (string | number)[]
): string => {
  return resolve(bundles, locale, msgKey, params) ?? msgKey;
};

export const withBundle = (
  bundle: I18NBundles,
  fallbackBundle: I18NBundles,
  locale: string,
  msgKey: string,
  params?: (string | number)[]
): string => {
  return (
    resolve(bundle, locale, msgKey, params) ??
    resolve(fallbackBundle, locale, msgKey, params) ??
    msgKey
  );
};
