import React from "react";

export type ChildrenFunc<T = {}> = (props: T) => React.ReactNode;

export const isFunction = (obj: any): obj is Function =>
  typeof obj === "function";

export type ChildrenWithFunc<C> = ChildrenFunc<C> | React.ReactNode;

export type PropWithChildrenFunc<P = {}, C = {}> = {
  children?: ChildrenFunc<C>;
} & P;
