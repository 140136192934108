import React from "react";

export const ProfileIcon = React.memo(() => {
  return (
    <span className="uc_inline-flex uc_justify-center uc_items-center ml-4">
      <svg
        className="uc_w-6 uc_h-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
        ></path>
      </svg>
    </span>
  );
});
