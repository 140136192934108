import React, { PropsWithChildren, ReactNode } from "react";

import { Padding } from "../helper";
import { Card, CardBody, CardHeader } from "../card";
import { ChildrenFunc, PropWithChildrenFunc } from "../misc";

const PulseLine = React.memo(() => (
  <div className="uc_grid uc_grid-cols-4 uc_gap-2">
    <div className="uc_h-2 uc_bg-slate-300 uc_rounded uc_col-span-1"></div>
    <div className="uc_h-2 uc_bg-slate-300 uc_rounded uc_col-span-1"></div>
    <div className="uc_h-2 uc_bg-slate-300 uc_rounded uc_col-span-1"></div>
    <div className="uc_h-2 uc_bg-slate-300 uc_rounded uc_col-span-1"></div>
  </div>
));

export const CardPulse = React.memo(
  (props: {
    show_border?: boolean;
    show_header?: boolean;
    body_padding?: Padding;
    num_of_lines?: number;
  }) => {
    const {
      show_border = false,
      body_padding = "Default",
      show_header = true,
      num_of_lines = 5,
    } = props;

    const createLines = (): ReactNode[] => {
      const nodes: ReactNode[] = [];
      for (let index = 0; index < num_of_lines; index++) {
        nodes.push(<PulseLine key={index} />);
      }
      return nodes;
    };

    return (
      <Card
        show_border={show_border}
        style_names={["uc_animate-pulse"]}
        padding_y="P1"
      >
        {show_header ? (
          <CardHeader>
            <div className="uc_h-2 uc_bg-slate-300 uc_rounded"></div>
          </CardHeader>
        ) : null}
        <CardBody padding={body_padding}>
          <div className="uc_space-y-3">{createLines()}</div>
        </CardBody>
      </Card>
    );
  }
);

export const LoadingZone = <T,>(props: {
  children: ChildrenFunc<T>;
  value: T | null | undefined;
}) => {
  const { value, children } = props;
  return <>{value ? children(value) : <CardPulse />}</>;
};
