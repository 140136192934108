import React, { PropsWithChildren, useMemo } from "react";
import { css } from "../../helper";
import { PropsWithStyle } from "../../type.common";

export const HeadingSub = (props: PropsWithChildren<PropsWithStyle<{}>>) => {
  const { style_names } = props;
  const styles = useMemo(() => {
    return css(style_names);
  }, [style_names]);

  return <span className={`uc_text-2xl ${styles}`}>{props.children}</span>;
};
