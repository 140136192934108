import React, { PropsWithChildren, useMemo } from "react";

export const List = (props: PropsWithChildren<{ numberic?: boolean }>) => {
  const { children, numberic = false } = props;
  const styles = useMemo(() => {
    const elems = ["uc_list-inside"];
    if (numberic) {
      elems.push("uc_list-decimal");
    } else {
      elems.push("uc_list-disc");
    }
    return elems.join(" ");
  }, [numberic]);

  return (
    <>
      {children ? (
        <ul className={styles}>
          {React.Children.map(children, (it, index) => {
            return <li key={index}>{it}</li>;
          })}
        </ul>
      ) : null}
    </>
  );
};
