import React from "react";
import GridLoader from "react-spinners/GridLoader";

export const Loading = <T,>(props: { loadingColor?: string }) => {
  const { loadingColor } = props;
  return (
    <div className="uc_flex">
      <GridLoader color={loadingColor} margin={4} size={11}></GridLoader>
    </div>
  );
};
