import React from "react";
import { BtnCircle } from "../btn_circle";
import { TrashIcon } from "../icons";

export const BtnIconRemove = (props: { doOnClick: () => void }) => {
  const { doOnClick } = props;
  return (
    <BtnCircle
      label="edit"
      doOnClick={doOnClick}
      style_names={["uc_btn_circle"]}
    >
      <TrashIcon />
    </BtnCircle>
  );
};
