import React from "react";
import { ThemeData } from "./theme.type";

const defaultTheme: ThemeData = {
  errorColor: "",
  fontFamily: "",
  primaryColor: "",
  primaryColorDark: "",
  primaryColorLight: "",
  bgColor: "",
  textTheme: {
    headline1: "",
    headline2: "",
    headline3: "",
    headline4: "",
    headline5: "",
    headline6: "",
  },
  primaryTextTheme: {
    headline1: "",
    headline2: "",
    headline3: "",
    headline4: "",
    headline5: "",
    headline6: "",
  },
  buttonTheme: {
    colorScheme: {
      bgColor: "",
      hoverColor: "",
    },
  },
};

const ThemeContext = React.createContext<ThemeData>(defaultTheme);
export default ThemeContext;
