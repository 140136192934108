import React, { PropsWithChildren, useMemo } from "react";
import {
  PaddingXCss,
  PaddingYCss,
  BorderColorCss,
  PaddingCss,
  BgColor,
  BgColorCss,
} from "../../helper";
import { useCardContext } from "../card.hook";

export const CardHeader = (
  props: PropsWithChildren<{ reset_style?: boolean; bgcolor?: BgColor }>
) => {
  const { reset_style = false, bgcolor } = props;

  const context = useCardContext();

  const styles = useMemo(() => {
    const names = [];
    names.push("uc_w-full", "uc_self-start");
    if (context.padding_x) {
      names.push(PaddingXCss[context.padding_x]);
    }
    if (context.padding_y) {
      names.push(PaddingYCss[context.padding_y]);
    }
    if (context.padding) {
      names.push(PaddingCss[context.padding]);
    }
    if (reset_style) {
      return names.join(" ");
    }

    if (context.show_border) {
      names.push("uc_rounded-t-md", "uc_border-b", BorderColorCss.Default);
    } else {
      names.push("uc_rounded-t-md", "uc_border-b", BorderColorCss.Transparent);
    }

    const bg = BgColorCss[bgcolor ? bgcolor : context.bgcolor];
    if (bg) {
      names.push(bg);
    }
    return names.join(" ");
  }, [reset_style, bgcolor]);

  return <div className={styles}>{props.children}</div>;
};
