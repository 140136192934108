import React from "react";
import { AppContext } from "../../app_context";
import { text } from "../../i18n/i18n.helper";
import { PropWithChildrenFunc } from "../../misc";

export const LocalizedText = (props: {
  msgKey: string;
  params?: (string | number)[];
}) => {
  const { msgKey, params } = props;
  return (
    <AppContext.Consumer>
      {({ i18NBundles, getLocale }) => {
        const locale = getLocale();
        return <>{text(i18NBundles, locale, msgKey, params)}</>;
      }}
    </AppContext.Consumer>
  );
};

export const LocalizedValue = (
  props: PropWithChildrenFunc<
    { values?: { [key: string]: string } },
    { text?: string }
  >
) => {
  const { values, children } = props;

  return (
    <>
      {values ? (
        <AppContext.Consumer>
          {({ i18NBundles, getLocale }) => {
            const locale = getLocale();
            const value = values[`${locale}`];
            return (
              <>
                {value ? (children ? children({ text: value }) : value) : null}
              </>
            );
          }}
        </AppContext.Consumer>
      ) : null}
    </>
  );
};
