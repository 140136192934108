import React, { PropsWithChildren, useMemo } from "react";
import { PropsWithStyle } from "../type.common";
import { css } from "../helper";

export const Central = (props: PropsWithChildren<{}> & PropsWithStyle<{}>) => {
  const { style_names } = props;
  const styles = useMemo(() => {
    return css(style_names);
  }, [style_names]);

  return (
    <div className={`uc_flex uc_justify-center uc_items-center ${styles}`}>
      {props.children}
    </div>
  );
};
