import React, { useMemo, useContext } from "react";
import { Locales } from "../helper";
import { AppContext } from "../app_context";
import { SimpleDateTimeFormatterProps } from "./date_formatter.type";

const days: { [locale: string]: string[] } = {
  [Locales.En_US]: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  [Locales.Vi_VN]: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
};

const months: { [locale: string]: string[] } = {
  [Locales.En_US]: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  [Locales.Vi_VN]: [
    "Tháng 1",
    "Tháng 2",
    "Tháng 3",
    "Tháng 4",
    "Tháng 5",
    "Tháng 6",
    "Tháng 7",
    "Tháng 8",
    "Tháng 9",
    "Tháng 10",
    "Tháng 11",
    "Tháng 12",
  ],
};

export const SimpleDateTimeFormatter = (
  props: SimpleDateTimeFormatterProps
) => {
  const {
    isoDateValue,
    showTime = false,
    showDayName = false,
    localTime,
  } = props;
  const context = useContext(AppContext);

  const formatNumber = (value: number): string => {
    return String(value).padStart(2, "0");
  };

  const formattedDate = useMemo(() => {
    const locale = context.getLocale();
    if (!months[locale] || !days[locale]) {
      return isoDateValue;
    }
    const date = new Date(isoDateValue);
    const year = date.getFullYear();
    const monthName = months[locale][date.getMonth()];
    const dayName = days[locale][date.getDay()];
    let formatted = `${formatNumber(date.getDate())} ${monthName} ${year}`;
    if (showDayName) {
      formatted = `${dayName}, ${formatted}`;
    }
    if (showTime) {
      formatted = `${formatted}, ${formatNumber(
        date.getHours()
      )}:${formatNumber(date.getMinutes())}`;
    }
    return formatted;
  }, [isoDateValue, context, showTime, showDayName]);

  return <span>{formattedDate}</span>;
};
