import React, { useMemo } from "react";
import { Btn } from "../btn_normal";
import { BtnProps } from "../type.common";
import { BtnCss, css } from "../helper";

export const BtnSecondary = (props: BtnProps) => {
  const { style_names, ...rest } = props;

  const styles = useMemo(() => {
    const names = [`${BtnCss.Secondary}`];
    if (style_names) {
      names.push(css(style_names));
    }
    return names;
  }, [style_names]);

  return (
    <Btn style_names={styles} {...rest}>
      {props.children}
    </Btn>
  );
};
