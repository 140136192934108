import React from "react";
import { HomeIcon } from "./home.component";
import { LogoutIcon } from "./logout.component";
import { ProfileIcon } from "./profile.component";
import { SettingsIcon } from "./settings.component";
import { DownloadIcon } from "./download.component";

const icons = {
  home: <HomeIcon />,
  logout: <LogoutIcon />,
  profile: <ProfileIcon />,
  settings: <SettingsIcon />,
  download: <DownloadIcon />,
};

export const Icon = ({ id }) => {
  return icons[id] || "";
};
