import React, { PropsWithChildren, useMemo } from "react";

export const EmptyLayout = (props: PropsWithChildren<{ center?: boolean }>) => {
  const { center = false } = props;
  const classNames = useMemo(() => {
    const names = ["uc_flex"];
    if (center) {
      names.push("uc_items-center uc_justify-center uc_h-screen");
    }
    return names.join(" ");
  }, [center]);
  return <div className={classNames}>{props.children}</div>;
};
