import React, { PropsWithChildren, useMemo } from "react";
import { PropsWithStyle } from "../type.common";
import { css } from "../helper";

const column_mapping: { [key: number]: string } = {
  1: "md:uc_grid-cols-1",
  2: "md:uc_grid-cols-2",
  3: "md:uc_grid-cols-3",
  4: "md:uc_grid-cols-4",
  5: "md:uc_grid-cols-5",
  6: "md:uc_grid-cols-6",
  7: "md:uc_grid-cols-7",
  8: "md:uc_grid-cols-8",
  9: "md:uc_grid-cols-9",
  10: "md:uc_grid-cols-10",
  11: "md:uc_grid-cols-11",
  12: "md:uc_grid-cols-12",
};

export const Column = (
  props: PropsWithChildren<
    PropsWithStyle<{
      space_between?: boolean;
      num_of_columns?: number;
    }>
  >
) => {
  const { style_names, space_between = true, num_of_columns = null } = props;
  const styles = useMemo(() => {
    const values = ["uc_grid", "uc_grid-cols-1", "uc_gap-4"];
    if (style_names) {
      values.push(css(style_names));
    }
    if (space_between) {
      values.push("uc_grid_space_between");
    }
    if (num_of_columns) {
      const col_style = column_mapping[num_of_columns];
      if (col_style) {
        values.push(col_style);
      }
    }
    return values.join(" ");
  }, [style_names, space_between, num_of_columns]);

  return <div className={styles}>{props.children}</div>;
};
