import React, { useMemo } from "react";

const TextBadge = (props: {
  text: string;
  uppercase?: boolean;
  styles: string;
}) => {
  const { text, uppercase = true, styles } = props;
  const class_name = useMemo(() => {
    if (uppercase) {
      return styles + " uc_uppercase";
    }
    return styles;
  }, [uppercase, styles]);
  return <span className={class_name}>{text}</span>;
};

export const GreenTextBadge = (props: {
  text: string;
  uppercase?: boolean;
}) => {
  const { text, uppercase = true } = props;
  return (
    <TextBadge
      text={text}
      uppercase
      styles="uc_bg-green-100 uc_text-green-800 uc_text-xs uc_font-medium uc_mr-2 uc_px-2.5 uc_py-0.5 uc_rounded uc_dark:bg-gray-700 uc_dark:text-green-400 uc_border uc_border-green-400"
    />
  );
};

export const RedTextBadge = (props: { text: string }) => {
  const { text } = props;
  return (
    <TextBadge
      text={text}
      uppercase
      styles="uc_bg-red-100 uc_text-red-800 uc_text-xs uc_font-medium uc_mr-2 uc_px-2.5 uc_py-0.5 uc_rounded uc_dark:bg-gray-700 uc_dark:text-red-400 uc_border uc_border-red-400"
    />
  );
};

export const YellowTextBadge = (props: { text: string }) => {
  const { text } = props;
  return (
    <TextBadge
      text={text}
      uppercase
      styles="uc_bg-yellow-100 uc_text-yellow-800 uc_text-xs uc_font-medium uc_mr-2 uc_px-2.5 uc_py-0.5 uc_rounded uc_dark:bg-gray-700 uc_dark:text-yellow-300 uc_border uc_border-yellow-300"
    />
  );
};

export const GrayTextBadge = (props: { text: string }) => {
  const { text } = props;
  return (
    <TextBadge
      text={text}
      uppercase
      styles="uc_bg-gray-100 uc_text-gray-800 uc_text-xs uc_font-medium uc_mr-2 uc_px-2.5 uc_py-0.5 uc_rounded uc_dark:bg-gray-700 uc_dark:text-gray-400 uc_border uc_border-gray-500"
    />
  );
};
