import React, { PropsWithChildren, useMemo } from "react";

import { PropsWithStyle } from "../../type.common";
import { CardContextInstance } from "../card.internal";
import {
  css,
  RoundedCss,
  BorderColorCss,
  Padding,
  PaddingX,
  PaddingY,
  BgColor,
  BgColorCss,
} from "../../helper";

export const Card = (
  props: PropsWithChildren<
    PropsWithStyle<{
      show_border?: boolean;
      padding?: Padding;
      padding_x?: PaddingX;
      padding_y?: PaddingY;
      bgcolor?: BgColor;
    }>
  >
) => {
  const {
    style_names,
    show_border = true,
    bgcolor,
    padding = "Default",
    padding_x,
    padding_y,
  } = props;
  const styles = useMemo(() => {
    const names = ["uc_flex uc_flex-col", "uc_border", RoundedCss.Rounded];
    if (style_names) {
      names.push(...style_names);
    }
    if (show_border) {
      names.push("uc_shadow-sm", BorderColorCss.Default);
    } else {
      names.push(BorderColorCss.Transparent);
    }
    if (bgcolor) {
      const bg = BgColorCss[bgcolor];
      if (bg) {
        names.push("uc_border-s-0", "uc_border-e-0", bg);
      }
    }
    return css(names);
  }, [style_names, show_border, bgcolor]);

  return (
    <CardContextInstance.Provider
      value={{
        show_border: show_border,
        bgcolor,
        padding,
        padding_x,
        padding_y,
      }}
    >
      <div className={styles}>{props.children}</div>
    </CardContextInstance.Provider>
  );
};
