import { useContext } from "react";
import { AppContext } from "../app_context";
import { I18NBundles } from "./i18n.type";
import { text, withBundle } from "./i18n.helper";

export const useI18n = (
  bundle?: I18NBundles
): ((key: string, params?: (string | number)[]) => string) => {
  const context = useContext(AppContext);

  const t = (key: string, params?: (string | number)[]): string => {
    return text(context.i18NBundles, context.getLocale(), key, params);
  };

  const fallbackT = (key: string, params?: (string | number)[]): string => {
    return withBundle(
      bundle,
      context.i18NBundles,
      context.getLocale(),
      key,
      params
    );
  };

  return bundle ? fallbackT : t;
};

export const useI18nFactory = (
  bundle: I18NBundles
): ((key: string, params?: (string | number)[]) => string) => {
  const context = useContext(AppContext);
  const t = (key: string, params?: (string | number)[]): string => {
    return withBundle(
      bundle,
      context.i18NBundles,
      context.getLocale(),
      key,
      params
    );
  };
  return t;
};

export const useSupportedLocales = (): { [languageCode: string]: string } => {
  const context = useContext(AppContext);
  return context.getSupportedLocales();
};

export const useSelectedLocale = (): string => {
  const context = useContext(AppContext);
  return context.getLocale();
};
