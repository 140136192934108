import React, { PropsWithChildren, useMemo } from "react";

import { useCardContext } from "../card.hook";
import { BgColor, BgColorCss, Padding, PaddingCss } from "../../helper";

export const CardBody = (
  props: PropsWithChildren<{ padding?: Padding; bgcolor?: BgColor }>
) => {
  const { padding, bgcolor } = props;

  const context = useCardContext();

  const styles = useMemo(() => {
    const names = ["uc_w-full"];
    let padding_text: Padding = padding || context.padding;
    if (padding_text) {
      names.push(PaddingCss[padding_text]);
    }
    const bg = BgColorCss[bgcolor ? bgcolor : context.bgcolor];
    if (bg) {
      names.push(bg);
    }

    return names.join(" ");
  }, [padding, bgcolor]);

  return <div className={styles}>{props.children}</div>;
};
