export enum FlexCss {
  Auto = "uc_flex-auto",
  None = "uc_flex-none",
  Default = "uc_flex-1",
  Initial = "uc_flex-initial",
}

export enum FlexDirectionCss {
  Row = "uc_flex-row",
  RowReverse = "uc_flex-row-reverse",
  Column = "uc_flex-col",
  ColumnReverse = "uc_flex-col-reverse",
}

export enum FlexWrapCss {
  Wrap = "uc_flex-wrap",
  WrapReverse = "uc_flex-wrap-reverse",
  No = "uc_flex-nowrap",
}

export enum FlexShrinkCss {
  Shrink = "uc_flex-shrink",
  Shrink0 = "uc_flex-shrink-0",
}

export enum FlexGrowCss {
  Grow = "uc_flex-grow",
  Grow0 = "uc_flex-grow-0",
}

export enum PaddingCss {
  Default = "uc_p-4",
  None = "uc_p-0",
  P1 = "uc_p-1",
  P1_5 = "uc_p-1.5",
  P2 = "uc_p-2",
  P2_5 = "uc_p-2.5",
  P3 = "uc_p-3",
  P3_5 = "uc_p-3.5",
}

export enum PaddingXCss {
  Default = "uc_px-4",
  None = "uc_px-0",
  P1 = "uc_px-1",
  P1_5 = "uc_px-1.5",
  P2 = "uc_px-2",
  P2_5 = "uc_px-2.5",
  P3 = "uc_px-3",
  P3_5 = "uc_px-3.5",
}

export enum PaddingYCss {
  Default = "uc_py-4",
  None = "uc_py-0",
  P1 = "uc_py-1",
  P1_5 = "uc_py-1.5",
  P2 = "uc_py-2",
  P2_5 = "uc_py-2.5",
  P3 = "uc_py-3",
  P3_5 = "uc_py-3.5",
}

export enum BgColorCss {
  Info = "uc_bg-info",
  Danger = "uc_bg-danger",
  DangerLight = "uc_bg-dangerLight",
  Warning = "uc_bg-warning",
  Secondary = "uc_bg-secondary",
  Background = "uc_bg-background",
  White = "uc_bg-white",
  Black = "uc_bg-black",
  Primary = "uc_bg-primary",
  PrimaryDark = "uc_bg-primaryDark",
  PrimaryLight = "uc_bg-primaryLight",
}

export enum TextColorCss {
  Info = "uc_text-info",
  Danger = "uc_text-danger",
  DangerLight = "uc_text-dangerLight",
  Warning = "uc_text-warning",
  Secondary = "uc_text-secondary",
  Primary = "uc_text-textPrimary",
  PrimaryDark = "uc_text-textPrimaryDark",
  PrimaryLight = "uc_text-textPrimaryLight",
  Black = "uc_text-black",
}

export enum TextTransformCss {
  Uppercase = "uc_uppercase",
  Lowercase = "uc_lowercase",
  Capitalize = "uc_capitalize",
  NormalCase = "uc_normal-case",
}

export enum SizingCss {
  FullWidth = "uc_w-full",
  FullVWidth = "uc_w-screen",
  FullHeigth = "uc_h-full",
  FullVHeight = "uc_h-screen",
  FullWidth_MD = "md:uc_w-full",
  FullVWidth_MD = "md:uc_w-screen",
  FullHeigth_MD = "md:uc_h-full",
  FullVHeight_MD = "md:uc_h-screen",
}

export enum Locales {
  En_US = "en_US",
  Vi_VN = "vi_VN",
}

export enum JustifyContentCss {
  End = "uc_justify-end",
  Start = "uc_justify-start",
  Center = "uc_justify-center",
  Around = "uc_justify-around",
  Evenly = "uc_justify-evenly",
  Between = "uc_justify-between",
}

export enum AlignItemsCss {
  End = "uc_items-end",
  Start = "uc_items-start",
  Center = "uc_items-center",
  Baseline = "uc_items-baseline",
  Stretch = "uc_items-stretch",
}

export enum RoundedCss {
  Rounded = "uc_rounded-md",
}

export enum ShadowCss {
  Shadow = "uc_shadow-sm",
}

export enum BorderCss {
  Border = "uc_border",
  BorderTop = "uc_border-t",
  BorderRight = "uc_border-r",
  BorderLeft = "uc_border-l",
  BorderBottom = "uc_border-b",
}

export enum TextSizeCss {
  Xs = "uc_text-xs",
  Sm = "uc_text-sm",
  Base = "uc_text-base",
  Lg = "uc_text-lg",
  Xl = "uc_text-xl",
  Xl1 = "uc_text-1xl",
  Xl2 = "uc_text-2xl",
  Xl3 = "uc_text-3xl",
  Xl4 = "uc_text-4xl",
  Xl5 = "uc_text-5xl",
  Xl6 = "uc_text-6xl",
  Xl7 = "uc_text-7xl",
  Xl8 = "uc_text-8xl",
  Xl9 = "uc_text-9xl",
}

export enum FontWeightCss {
  Thin = "uc_font-thin",
  Extralight = "uc_font-extralight",
  Light = "uc_font-light",
  Normal = "uc_font-normal",
  Medium = "uc_font-medium",
  Semibold = "uc_font-semibold",
  Bold = "uc_font-bold",
  Extrabold = "uc_font-extrabold",
  Black = "uc_font-black",
}

export enum GridRowCss {
  Row1 = "uc_grid-rows-1",
  Row2 = "uc_grid-rows-2",
  Row3 = "uc_grid-rows-3",
  Row4 = "uc_grid-rows-4",
  Row5 = "uc_grid-rows-5",
  Row6 = "uc_grid-rows-6",
  None = "uc_grid-rows-none",
}

export enum GridRowMdCss {
  Row1 = "md:uc_grid-rows-1",
  Row2 = "md:uc_grid-rows-2",
  Row3 = "md:uc_grid-rows-3",
  Row4 = "md:uc_grid-rows-4",
  Row5 = "md:uc_grid-rows-5",
  Row6 = "md:uc_grid-rows-6",
  None = "md:uc_grid-rows-none",
}

export enum GridColCss {
  Col1 = "uc_grid-cols-1",
  Col2 = "uc_grid-cols-2",
  Col3 = "uc_grid-cols-3",
  Col4 = "uc_grid-cols-4",
  Col5 = "uc_grid-cols-5",
  Col6 = "uc_grid-cols-6",
  Col7 = "uc_grid-cols-7",
  Col8 = "uc_grid-cols-8",
  Col9 = "uc_grid-cols-9",
  Col10 = "uc_grid-cols-10",
  Col11 = "uc_grid-cols-11",
  Col12 = "uc_grid-cols-12",
  None = "uc_grid-cols-none",
}

export enum GridColMdCss {
  Col1 = "md:uc_grid-cols-1",
  Col2 = "md:uc_grid-cols-2",
  Col3 = "md:uc_grid-cols-3",
  Col4 = "md:uc_grid-cols-4",
  Col5 = "md:uc_grid-cols-5",
  Col6 = "md:uc_grid-cols-6",
  Col7 = "md:uc_grid-cols-7",
  Col8 = "md:uc_grid-cols-8",
  Col9 = "md:uc_grid-cols-9",
  Col10 = "md:uc_grid-cols-10",
  Col11 = "md:uc_grid-cols-11",
  Col12 = "md:uc_grid-cols-12",
  None = "md:uc_grid-cols-none",
}

export enum GridFlowCss {
  Row = "uc_grid-flow-row",
  RowDense = "uc_grid-flow-row-dense",
  Col = "uc_grid-flow-col",
  ColDense = "uc_grid-flow-col-dense",
}

export enum GridFlowMdCss {
  Row = "md:uc_grid-flow-row",
  RowDense = "md:uc_grid-flow-row-dense",
  Col = "md:uc_grid-flow-col",
  ColDense = "md:uc_grid-flow-col-dense",
}

export enum DisplayCss {
  Flex = "uc_flex",
  Grid = "uc_grid",
}

export enum GapCss {
  Gap1 = "uc_gap-1",
  Gap1_5 = "uc_gap-1.5",
  Gap2 = "uc_gap-2",
  Gap2_5 = "uc_gap-2.5",
  Gap3 = "uc_gap-3",
  Gap3_5 = "uc_gap-3.5",
  Gap4 = "uc_gap-4",
}

export enum GapRowCss {
  Gap1 = "uc_gap-y-1",
  Gap1_5 = "uc_gap-y-1.5",
  Gap2 = "uc_gap-y-2",
  Gap2_5 = "uc_gap-y-2.5",
  Gap3 = "uc_gap-y-3",
  Gap3_5 = "uc_gap-y-3.5",
  Gap4 = "uc_gap-y-4",
}

export enum GapColCss {
  Gap1 = "uc_gap-x-1",
  Gap1_5 = "uc_gap-x-1.5",
  Gap2 = "uc_gap-x-2",
  Gap2_5 = "uc_gap-x-2.5",
  Gap3 = "uc_gap-x-3",
  Gap3_5 = "uc_gap-x-3.5",
  Gap4 = "uc_gap-x-4",
}

export enum BorderColorCss {
  Default = "uc_border-gray-200",
  Transparent = "uc_border-transparent",
  Curent = "uc_border-current",
  Inherit = "uc_border-inherit",
}

export enum BorderStyleCss {
  Dashed = "uc_border-dashed",
  DashedHover = "hover:uc_border-dashed",
  Solid = "uc_border-solid",
  SolidHover = "hover:uc_border-solid",
  Dotted = "uc_border-dotted",
  DottedHover = "hover:uc_border-dotted",
}

export type Css =
  | DisplayCss
  | AlignItemsCss
  | JustifyContentCss
  | BgColorCss
  | FlexCss
  | FlexWrapCss
  | FlexShrinkCss
  | FlexGrowCss
  | PaddingCss
  | PaddingXCss
  | PaddingYCss
  | TextColorCss
  | TextTransformCss
  | SizingCss
  | RoundedCss
  | ShadowCss
  | BorderCss
  | TextSizeCss
  | FontWeightCss
  | GridRowCss
  | GridRowMdCss
  | GridColCss
  | GridColMdCss
  | GridFlowCss
  | GridFlowMdCss
  | GapCss
  | GapRowCss
  | GapColCss
  | BorderColorCss
  | BorderStyleCss
  | string;

export const css = (all?: Css[]): string => {
  if (all) {
    return all.filter((it) => it).join(" ");
  }
  return "";
};

export enum BtnCss {
  Primary = "!uc_bg-primary uc_py-2 uc_px-4 uc_border uc_border-transparent uc_font-medium uc_rounded-md !uc_text-textPrimaryDark hover:!uc_bg-primaryLight focus:uc_outline-none focus:uc_ring-2 focus:uc_ring-offset-1 focus:uc_ring-primaryLight uc_shadow-sm",
  Secondary = `!uc_bg-secondary uc_py-2 uc_px-4 uc_border uc_border-transparent uc_font-medium uc_rounded-md !uc_text-white hover:!uc_bg-secondary focus:uc_outline-none focus:uc_ring-2 focus:uc_ring-offset-1 focus:ring-secondary uc_shadow-sm`,
  Danger = `!uc_bg-danger uc_py-2 uc_px-4 uc_rounded-md uc_border uc_border-transparent uc_shadow-sm uc_font-medium !uc_text-white hover:!uc_bg-red-700 focus:uc_outline-none focus:uc_ring-2 focus:uc_ring-offset-1`,
  Cancel = `!uc_bg-gray-200 uc_py-2 uc_px-4 uc_border uc_border-transparent uc_rounded-md !uc_text-textPrimaryDark uc_font-medium hover:!uc_bg-gray-100 focus:uc_outline-none focus:uc_ring-2 focus:uc_ring-offset-1 focus:uc_ring-gray-400 uc_shadow-sm`,
}

export const flexAlignments = {
  end: JustifyContentCss.End,
  start: JustifyContentCss.Start,
  center: JustifyContentCss.Center,
  between: JustifyContentCss.Between,
};

export type KeyType<T> = {
  [key in keyof T]: boolean;
};

export type FlexAlignment = keyof KeyType<typeof flexAlignments>;
export type Padding = keyof KeyType<typeof PaddingCss>;
export type TextColor = keyof KeyType<typeof TextColorCss>;
export type PaddingX = keyof KeyType<typeof PaddingXCss>;
export type PaddingY = keyof KeyType<typeof PaddingYCss>;
export type Flex = keyof KeyType<typeof FlexCss>;
export type FlexDirection = keyof KeyType<typeof FlexDirectionCss>;
export type TextSize = keyof KeyType<typeof TextSizeCss>;
export type FontWeight = keyof KeyType<typeof FontWeightCss>;
export type BgColor = keyof KeyType<typeof BgColorCss>;
export type BorderStyle = keyof KeyType<typeof BorderStyleCss>;
