import React, { PropsWithoutRef, useState } from "react";
import { Column } from "../column";
import { TabItem } from "./tab.type";

export const Tab = (
  props: PropsWithoutRef<{
    items: TabItem[];
    selectedTab?: string;
    showPaddingLeft?: boolean;
    onSelectedTab?: (tabId: string) => void;
  }>
) => {
  const { items, selectedTab, showPaddingLeft = false, onSelectedTab } = props;
  const [selectedTabId, setSelectedTabId] = useState(() => {
    if (selectedTab) {
      return selectedTab;
    }
    return "0"; // first tab index
  });

  return (
    <Column>
      <div className="uc_w-full uc_py-1.5 sm:uc_px-0 uc_rounded-md uc_bg-gray-300/20">
        <ul
          className={`uc_flex uc_flex-row uc_space-x-2 ${
            showPaddingLeft ? "uc_px-1.5" : ""
          }`}
        >
          {items.map((item, index) => {
            const key = item.id ? item.id : `${index}`;
            return (
              <li
                key={index}
                className={
                  key === selectedTabId
                    ? "uc_rounded-md"
                    : "hover:uc_border-primary hover:uc_bg-primaryLight hover:uc_rounded-md"
                }
              >
                <button
                  onClick={() => {
                    if (onSelectedTab) {
                      onSelectedTab(key);
                    }
                    setSelectedTabId(key);
                  }}
                  className={`uc_flex uc_flex-row uc_rounded-md uc_items-center md:uc_items-end uc_no-underline uc_tracking-wide uc_font-medium uc_py-3 uc_px-4 uc_outline-none focus:uc_outline-none uc_h-full uc_space-x-1 ${
                    key === selectedTabId ? "uc_bg-primary" : ""
                  }`}
                >
                  {item.renderHeader
                    ? item.renderHeader()
                    : item.label
                    ? item.label
                    : ""}
                </button>
              </li>
            );
          })}
          <li className="uc_flex-auto uc_py-1.5"></li>
        </ul>
      </div>
      <>
        {items
          .filter((item, index) => {
            const key = item.id ? item.id : `${index}`;
            return key === selectedTabId;
          })
          .filter((it) => it)
          .map((item, index) => {
            return <div key={index}>{item.renderBody()}</div>;
          })
          .find((it) => it)}
      </>
    </Column>
  );
};
